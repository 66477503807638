import styles from "./contact.module.scss";
import ContactForm from "../../contact/contact"
const Contact = () => (
  <div id="contact" className={"d-flex flex-column pb-5 px-3 ".concat(styles.wrapper)}>
    <h1 className="text-white text-center mx-auto my-5 pt-5"><b>Temos todo o gosto em falar consigo<br />e conhecer o seu projeto.</b></h1>
    <div className="d-flex flex-row">
      <ContactForm />
    </div>
  </div>
);
export default Contact;