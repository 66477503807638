import { PropsWithChildren } from 'react';
import styles from './button.module.scss';

interface ButtonProps {
  className?: string
  type?: 'submit',
  disabled?: boolean,
  onClick?: () => void
}

const Button = ({className, type, children, onClick}: PropsWithChildren<ButtonProps>) => (
  <button onClick={onClick} type={type} className={"px-4 py-3 ".concat(styles.button, " " + className)}>
    <h5 className='m-0 text-white'><b>{children}</b></h5>
  </button>
)
export default Button;