import { Formik } from "formik";
import { Form } from "react-router-dom";
import Input from "../../atoms/forms/input";
import Logo from "../../atoms/logo/logo";
import styles from "./footer.module.scss";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { ReactComponent as In } from "./icons/in.svg";
import { ReactComponent as Insta } from "./icons/insta.svg";
import MailchimpSubscribe from "react-mailchimp-subscribe"




const Footer = () => {
  const mcURL = "https://notdigital.us14.list-manage.com/subscribe/post?u=0fc70b3c517a3609410175159&amp;id=5e93f574d8&amp;f_id=00d3b0e5f0"
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-md-row px-4 py-5">
        <div className={[styles.logo, "col-md-2 mx-lg-4"].join(" ")}>
          <Logo />
        </div>
        <div className="d-flex flex-column col-md-4 mx-md-5">
          <h4 className="px-md-3">Quer ficar a par de todas<br />as novidades e promoções?</h4>
          {/* <MailchimpSubscribe url={mcURL}></MailchimpSubscribe> */}
          <MailchimpSubscribe
            url={mcURL}
            render={({ subscribe, status, message }) => (
              <div>
                <Formik
                  initialValues={{ email: '' }}
                  validate={values => {
                    const errors: { email?: string } = {};
                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    subscribe({ EMAIL: values.email });
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, submitForm }) => (
                    <Form className={"d-flex flex-column position-relative mt-4 ".concat(styles.input)}>
                      <Input wrapperClassName={"px-0 px-md-3 "} borderColor="red" name="email" type="email" label="E-mail" labelBold={false} />
                      <Arrow className={styles.submit} width={39} height={39} onClick={submitForm} />
                    </Form>

                  )}
                </Formik>
                {status === "success" && <div className="px-md-3 mt-2"><p><b>Obrigado por subscrever!</b></p></div>}
                {status === "error" && <div className="px-md-3 mt-2"><p><b>Ocorreu um erro, tente mais tarde.</b></p></div>}
              </div>
            )}
          />

        </div>
        <div className="mt-5 mt-md-0 mx-md-5 px-lg-5">
          <p className={styles.light}>
            Rua Abel Santos 83 | 83A<br />
            Edifício Not Digital | Caparide<br />
            2785-175 São Domingos de Rana<br />
            <br />
            (+351) 214 667 130<br />
            somos@notdigital.pt<br />
          </p>
        </div>
        <div className="ms-auto">
          {/* <a href="">
            <Face className={["mx-2", styles.social].join(" ")} width={39} height={39} />
          </a> */}
          <a href="https://www.instagram.com/notdigital.pt/">
            <Insta className={["mx-2", styles.social].join(" ")} width={39} height={39} />
          </a>
          <a href="https://www.linkedin.com/company/not-digital/">
            <In className={["mx-2", styles.social].join(" ")} width={39} height={39} />
          </a>
        </div>
      </div>
      <p className="ms-auto me-4 px-2"><small>@ 2024 NotDigital. Todos os Direitos Reservados.</small></p>
    </div>
  )
}

export default Footer;