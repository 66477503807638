import { PropsWithChildren } from 'react';
import UnderlineRedSvg from './underline-red.svg';
import UnderlineBlueSvg from './underline-blue.svg';

interface UnderlineProps {
  color?: 'red' | 'blue'
}

const options = {
  red: () => UnderlineRedSvg,
  blue: () => UnderlineBlueSvg 
}

const Underline = ({ children, color = 'red' }: PropsWithChildren<UnderlineProps>) => {
 
  return (
    <span className='position-relative'>
      {children}
      <div style={{
        backgroundImage: `url(${options[color]()})`, backgroundRepeat: 'no-repeat', position: 'absolute', width: '100%',
        height: '30px', left: 0,
        top: '90%', backgroundSize: '95%', backgroundPosition: 'center top'
      }}></div>
    </span>
  )
}

export default Underline;