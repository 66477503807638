import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import a from './items/1.png';
import b from './items/2.png';
import c from './items/3.png';
import d from './items/4.png';
import e from './items/5.png';
import f from './items/6.png';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import styles from "./carousel.module.scss"

const Carousel = () => {
  const renderArrowNext = (clickHandler: () => void, hasNext: boolean, label: string) => {
    return <div className={styles.right} onClick={clickHandler}>
      <ArrowRight />
    </div>
  }
  const renderArrowPrev = (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return <div className={styles.left} onClick={clickHandler}>
      <ArrowRight />
    </div>
  }

  const renderItem = (item: React.ReactNode, options?: { isSelected: boolean }) => {
    return <div className={styles.item}>
      {item}
    </div>
  }

  const images = [a, b, c, d, e, f]

  return (
    <ResponsiveCarousel
      showStatus={false}
      width={'90%'}
      className="w-100"
      showThumbs={false}
      renderArrowNext={renderArrowNext}
      renderArrowPrev={renderArrowPrev}
      renderItem={renderItem}
      infiniteLoop={true}
    >
      {images.map(i =>
        <div>
          <img src={i} alt="1" />
        </div>
      )}

    </ResponsiveCarousel>
  )
}

export default Carousel;