import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from "formik";
import Button from "../../atoms/button/button";
import Input from "../../atoms/forms/input";
import styles from "./contact.module.scss";
import Underline from '../../atoms/underline/underline';

const Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className={"d-flex flex-column bg-white mx-3 mx-md-auto p-4 p-md-5 ".concat(styles.wrapper)}>
      {isSubmitted ? (
        <div className="thank-you-message">
          <h3 className="text-center"><b><Underline color="blue">Obrigado pela sua mensagem!</Underline></b></h3>
          <p className="text-center mt-4">Entraremos em contacto com a maior brevidade possível.</p>
        </div>
      ) : (
        <Formik
          initialValues={{ name: '', email: '', phone: '', subject: '', content: '', privacy: false, marketing: false }}
          validate={values => {
            const errors: { email?: string } = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setTimeout(() => {
              fetch('https://formsubmit.co/ajax/somos@notdigital.pt', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                },
                body: JSON.stringify(values, null, 2),
              }).then(() => {
                setIsSubmitted(true);
                setSubmitting(false);
              }).catch(() => {
                alert('Ocorreu um erro ao enviar a mensagem. Por favor, tente mais tarde.');
                setSubmitting(false);
                setIsSubmitted(false);
              });
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="d-flex flex-column">
              <div className="d-flex flex-column flex-md-row">
                <Input type="text" name="name" label="Nome" wrapperClassName="col-md-4 px-2 mt-3 mt-md-0" className="" />
                <Input type="email" name="email" label="E-mail" wrapperClassName="col-md-4 px-2 mt-3 mt-md-0" className="" />
                <Input type="phone" name="phone" label="Telefone" wrapperClassName="col-md-4 px-2 mt-3 mt-md-0" className="" />
              </div>
              <div className="d-flex flex-row mt-md-3">
                <Input type="text" name="subject" label="Assunto" wrapperClassName="col-12 px-2 mt-3 mt-md-0" className="" />
              </div>
              <div className="d-flex flex-row mt-md-3">
                <Input type="text" name="content" textarea label="Mensagem" wrapperClassName="col-12 px-2 mt-3 mt-md-0" className="" />
              </div>
              <ErrorMessage name="email" component="div" />
              <div className="px-3 mt-4">
                <label>
                  <Field type="checkbox" name="privacy" />
                  <span className="ms-2">Li e aceito a <a color="black" href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">Política de Privacidade</a>.</span>
                </label>
              </div>
              <div className="px-3">
                <label>
                  <Field type="checkbox" name="marketing" />
                  <span className="ms-2">Autorizo que os meus dados pessoais, sejam objeto de tratamento pela Not Digital para ações de marketing, divulgação de notícias, eventos e promoções de serviços.</span>
                </label>
              </div>
              <Button type="submit" disabled={isSubmitting} className="mt-4 mx-auto">
                {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Enviar'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Contact;