import { ReactComponent as Graphics } from "./icons/graphics.svg"
import { ReactComponent as Formats } from "./icons/formats.svg"
import { ReactComponent as Merch } from "./icons/merch.svg"
import { ReactComponent as Packaging } from "./icons/packaging.svg"
import Underline from "../../../atoms/underline/underline"

const Services = () => (
  <div className="d-flex flex-column my-5 py-5">
    <div className="d-flex flex-row justify-content-center py-5">
      <h2 className="text-center">
        <b><Underline color="blue">Mais de 30 anos de experiência</Underline></b>
        <br />fazem a diferença e uma gama de serviços
        <br />especializados faz mais e melhor
      </h2>
    </div>
    <div className="d-flex flex-column flex-md-row my-5 mx-md-5 px-4">
      <div className="d-flex flex-column col-md-3 mt-4 mt-md-0 px-3 px-md-5">
        <Graphics />
        <h3 className="mt-2 mt-md-5 mb-4"><b>Produção Gráfica</b></h3>
        <p><b>Pela imagem da sua empresa</b><br/>
          produzimos todo o tipo de peças, da
          seleção de materiais à pré-impressão,
          produção e acabamentos.</p>
      </div>
      <div className="d-flex flex-column col-md-3 mt-4 mt-md-0 px-3 px-md-5">
        <Formats />
        <h3 className="mt-2 mt-md-5 mb-4"><b>Grandes Formatos</b></h3>
        <p><b>Pela dimensão da sua campanha</b><br/>
          produzimos grandes materiais
          publicitários, do vinil às lonas,
          tecido ou papel.</p>
      </div>
      <div className="d-flex flex-column col-md-3 mt-4 mt-md-0 px-3 px-md-5">
        <Merch />
        <h3 className="mt-2 mt-md-5 mb-4"><b>Merchandising</b></h3>
        <p><b>Pelo poder da sua mensagem</b><br/>
          mantemo-la no dia a dia dos clientes,
          dos artigos promocionais comuns
          aos personalizados e com branding.</p>
      </div>
      <div className="d-flex flex-column col-md-3 mt-4 mt-md-0 px-3 px-md-5">
        <Packaging />
        <h3 className="mt-2 mt-md-5 mb-4"><b>Packaging</b></h3>
        <p><b>Pela comunicação do seu produto</b><br/>
          produzimos múltiplos formatos de
          packaging, com recurso a vários
          materiais e técnicas de impressão.</p>
      </div>
    </div>
  </div>
)

export default Services;