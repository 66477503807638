import { FieldHookConfig, useField } from "formik";
import { Ref, useMemo, useRef, useState } from "react";
import styles from "./input.module.scss";

interface InputProps {
  label: string
  labelBold?: boolean,
  wrapperClassName?: string
  textarea?: boolean
  type?: string,
  borderColor?: string,
}


const Input = ({ type, borderColor = "black", label, labelBold = true, textarea, wrapperClassName, ...props }: InputProps & FieldHookConfig<any>) => {
  const [active, setActive] = useState(false);

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const focusInput = () => {
    inputRef.current?.focus();
  };
  const [field] = useField(props);
  const labelClass = useMemo(() => [styles.label, labelBold ? styles.bold : "", active || field.value !== "" ? styles.active : ""].join(" "), [active, field.value, labelBold])
  const { onBlur, ...rest } = field
  return (
    <div className={`d-flex flex-column px-md-3 cursor-pointer ${wrapperClassName}`}>
      <p onClick={focusInput} className={labelClass}>{label}</p>
      {
        textarea ?
          (
            <textarea ref={inputRef as Ref<HTMLTextAreaElement>} onFocus={() => setActive(true)} onBlur={(ev) => { setActive(false) }} {...rest} style={{borderColor}} className={[styles.input, (props as any).className ?? ""].join(" ")} rows={4}></textarea>
          ) :
          (
            <input ref={inputRef as Ref<HTMLInputElement>} type={type} onFocus={() => setActive(true)} onBlur={(ev) => { setActive(false) }} {...rest} style={{borderColor}}  className={[styles.input, (props as any).className ?? ""].join(" ")} />
          )
      }

    </div>
  )
}

export default Input;