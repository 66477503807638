import Carousel from "../components/organisms/carousel/carousel";
import Footer from "../components/organisms/footer/footer";
import Header from "../components/organisms/header/header";
import Contact from "../components/organisms/sections/contact/contact";
import Intro from "../components/organisms/sections/intro/intro";
import Services from "../components/organisms/sections/services/services";

const HomePage = () => (
  <div className="d-flex flex-column">
    <Header />
    <Intro />
    <div className="d-flex flex-row justify-content-center">
      <Carousel />
    </div>
    <Services />
    <Contact />
    <Footer />
  </div>
)
export default HomePage;