import left from './left.png';
import right from './right.png';
import styles from './intro.module.scss';
import './carousel.scss'
import Button from '../../../atoms/button/button';
import Underline from '../../../atoms/underline/underline';
const scrollTo = () => {
  document.getElementById('contact')?.scrollIntoView({ 
    behavior: 'smooth' 
  });
}
const Intro = () => (
 

  <div className={"d-flex flex-row h-100 ".concat(styles.section)}>
    <div className='d-flex flex-row position-absolute w-100'>
      <img src={left} alt="Left" className={styles.left} />
      <img src={right} alt="Right" className={styles.right} />
    </div>
    <div className='d-flex flex-column m-auto z-index-1'>
      <h1 className='text-center mt-5 px-4 px-sm-0'>Num mundo cada vez mais digital, são as <br/><Underline><b>experiências físicas</b></Underline> que mais nos unem.</h1>
      <Button onClick={scrollTo} className="mx-auto mt-5 mt-md-4 text-white">Lance-nos um desafio</Button>
    </div>

  </div>
)

export default Intro;